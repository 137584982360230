<template>
  <ul class="list-none uppercase">
    <li
      v-for="(tab, index) in tabs"
      :key="index"
      class="mr-1 inline-block text-center text-xs font-normal leading-none last:mr-0"
    >
      <a
        :data-test="tab.replaceAll(' ', '-')"
        class="d-flex font-lato hover:border-b-p-yellow-400 hover:text-p-yellow-400 h-[32px] w-[100px] cursor-pointer items-center justify-center border-b border-transparent px-3 py-3 text-center text-[10px] font-normal leading-[10px] hover:border-b hover:opacity-100"
        :class="{
          'text-white opacity-60': activeTab !== tab,
          'border-b-p-yellow-400 text-p-yellow-400 opacity-100': activeTab === tab,
        }"
        @click="switchActiveTab(tab)"
      >
        <span class="mr-1 h-[11px] w-[11px]">
          <Icon
            :name="iconName(tab)"
            :class="{
              'tab-with-icons-icon-active': activeTab === tab,
            }"
            class="tab-with-icons-icon"
          />
        </span>
        <div>{{ tab }}</div>
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useGeneralStore } from '~/stores/general'

const generalStore = useGeneralStore()
const { changeFullScreenMode } = generalStore

withDefaults(
  defineProps<{
    tabs?: string[]
    activeTab: string
  }>(),
  {
    tabs: () => [],
  },
)

const emit = defineEmits(['switchActiveTab'])

const switchActiveTab = (tab) => {
  changeFullScreenMode(false)
  emit('switchActiveTab', tab)
}

const defaultIcons = {
  'operating country': 'country',
  'Cathode Chemistry': 'chemistry',
}

const iconName = (tab) => {
  const iconname = defaultIcons[tab] ? defaultIcons[tab] : tab
  return iconname.replaceAll(' ', '-').toLowerCase()
}
</script>

<style>
.tab-with-icons-icon {
  @apply m-auto mr-[4px] fill-current stroke-current;

  width: 11px;
  height: 11px;
}

.tab-with-icons-icon-active {
  @apply text-p-yellow-800;
}
</style>
